$(document).on('turbolinks:load', function () {
  /*==========  Search  ==========*/
  function positionSearch() {
    if ($(window).width() > $(window).height()) {
      var windowWidth = $(window).width();
      $('.search-overlay').css({'width': windowWidth*2.5, 'height': windowWidth*2.5});
    } else {
      var windowHeight = $(window).height();
      $('.search-overlay').css({'width': windowHeight*2.5, 'height': windowHeight*2.5});
    }
    var position = $('.header-open-search').offset();
    var height = $('.header-open-search').height();
    var width = $('.header-open-search').width();
    var top = position.top + height/2 - $('.search-overlay').outerHeight()/2;
    var left = position.left - width/2 - $('.search-overlay').outerWidth()/2;
    $('.search-overlay').css({'top': top, 'left': left});
  }
  positionSearch();
  $(window).on('resize', function() {
    positionSearch();
  });
  $('.open-search').on('click', function(event) {
    event.preventDefault();
    $('.search-overlay').toggleClass('scale');
    $('.search').toggleClass('open');
  });
  $('.search-close').on('click', function(event) {
    event.preventDefault();
    $('.search-overlay').removeClass('scale');
    $('.search').removeClass('open');
  });
  $('.navgation > a').on('click', function (event) {
    event.preventDefault();
  });
  /*-----------------------------------------------------------------------------------*/
  /*	ISOTOPE PORTFOLIO
  /*-----------------------------------------------------------------------------------*/
  $('.portfolio-wrapper').imagesLoaded(function () {
    // images have loaded
    // var $container = $('.portfolio-wrapper .project-index-items');
    // $container.isotope({
    //   itemSelector: '.project-index-items-item',
    //   layoutMode: 'masonry'
    // });


    // var filterProjects = (el) => {
    //   var selector = el.attr('data-filter');  
    //   $('.filter li').removeClass('active');
    //   el.parent().addClass('active');
      
    //   $container.isotope({
    //     filter: selector
    //   });
    // }

    // var mainPageFilter = "";
    // if (window.location.pathname == "/") {
    //   const urlParams = new URLSearchParams(window.location.search);
    //   mainPageFilter = urlParams.get('filter');
    //   el = $(`.filter li a[data-filter="${mainPageFilter}"]`);
    //   filterProjects(el)
    // }
    
    // $('.filter li a').click(function (event) {
    //   event.preventDefault();
    //   el = $(this)
    //   if (window.location.pathname != "/") {
    //     var selector = el.attr('data-filter');
    //     Turbolinks.visit(`/?filter=${selector}`)
    //   } else{
    //     filterProjects(el);
    //   }
    // })
  });
});