// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

require("trix")
require("@rails/actiontext")

/* ------------- */
/*  JS packages  */
/* ------------- */
// popper.js
// import * as Popper from "popper.js"; 

// tiny-slider
import 'tiny-slider/dist/tiny-slider.css'
// import { tns } from 'tiny-slider/src/tiny-slider.js'

// fontawesome 5
import "@fortawesome/fontawesome-free/scss/fontawesome.scss";
import "@fortawesome/fontawesome-free/js/all";

// Ionicons Font Icon version
import "ionicons/scss/ionicons";

/* ---------------- */
/*  jQuery plugins  */
/* ---------------- */
// waypoints
import "waypoints/lib/jquery.waypoints";

// isotope
var jQueryBridget = require('jquery-bridget');
var Isotope = require('isotope-layout');
jQueryBridget('isotope', Isotope, $);
// bootstrap
import "bootstrap/scss/bootstrap";
import "bootstrap";
// imagesloaded
var imagesLoaded = require('imagesloaded/imagesloaded.pkgd');
imagesLoaded.makeJQueryPlugin(jQuery);
// import "imagesloaded/imagesloaded.pkgd"

/* ------------- */
/*   custom js   */
/* ------------- */
import '../stylesheets/application'
import "../custom/application";

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)
